@import '~styles/normalize.scss';
@import '~styles/styles.module.scss';

html {
  line-height: 1.5;
  font-family: $mainFont;
}

body {
  background-color: $backgroundColor;
  margin: 0;
}

#root {
  height: 100vh;
}

.MuiAccordionDetails-root {
  padding-right: 0 !important;
  padding-left: 0 !important;
}
