@font-face {
  font-family: 'Lato';
  src: url('../assets/fonts/lato-regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Lato';
  src: url('../assets/fonts/lato-bold-webfont.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Lato';
  src: url('../assets/fonts/lato-italic-webfont.woff') format('woff');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Playfair';
  src: url('../assets/fonts/playfairdisplay-bolditalic-webfont.woff')
    format('woff');
  font-weight: bold;
  font-style: italic;
}

$colorBlue: #3e83ca;
$colorBlueAlt: #436cae;
$colorBlueAltDark: #2b538f;
$colorBlueDark: #175e9d;
$colorBlueDarker: #00285b;
$colorBlueLight: #ecf3fa;
$colorBlueLighter: #f2f5fb;
$colorBrightness_7: #111;
$colorBrightness_13: #222;
$colorBrightness_20: #333;
$colorBrightness_33: #555;
$colorBrightness_40: #666;
$colorBrightness_53: #888;
$colorBrightness_70: #b3b3b3;
$colorBrightness_87: #ddd;
$colorBusinessLinen: #fcf4ec;
$colorBusinessLinenDark: #f4ebe2;

$colorGreen: #390;
$colorGreenDark: #197b00;

$colorGrayBackgroundKlarna: #f2f2f1;
$colorGrayBackgroundLight: #f7f6f5;
$colorGray: #d6d6d6;
$colorGrayLight: #e8e8e8;
$colorGrayLighter: #f8f8f8;
$colorMagenta: #c3227d;
$colorMagentaAlt: #c6579a;
$colorOrange: #ff5f0e;
$colorOrangeDark: #f45200;
$colorRed: #f30;
$colorRedLight: #ffeaea;
$colorRedDark: #c80000;
$colorYellow: #ffcd00;
$colorYellowHighligt: #fffc88;
$colorYellowBackground: #fcf5e5;
$colorGridBorder: rgba(0, 0, 0, 0.1);
$colorGridBorderOpaque: #e5e5e5;

$lightenGreen: #e7f8ee;
$lightenYellow: #fffded;
$lightenBlue: #e2effa;
$lightenPurple: #ede0f3;
$lightenGrey: #ebedef;
$lightenDarkRed: #fdeded;
$lightenRed: #fcecec;

$colorText: #222;

$colorDarkBackground: #101010;

$boxShadow: 0 5px 25px rgba(0, 0, 0, 0.2);
$borderColor: rgb(214, 214, 214);
// font sizes
$text-small: 12px;
$text-normal: 14px;

// new styles

$whiteColor: #ffffff;
$blackColor: #000000;
$linkColor: #2779bd;

$lightBlueColor: #6cb2eb;
$lighterBlueColor: #bcdefa;
$blueColor: #3490dc;
$blueMediumColor: #2779bd;
$darkBlueColor: #1c3d5a;

$greenColor: #38c172;
$darkGreenColor: #1f9d55;
$lightGreenColor: #6FAB48;

$greyColor: #8795a1;

$backgroundColor: #dae1e7;

$lightRedHoverColor: #f9acaa;

$aliceBlue: #eff8ff;
$yellowColor: #ffed4a;
$lightYellowColor: #fff9c2;
$lightGreyColor: #b8c2cc;
$darkGreyColor: #606f7b;
$lightRedColor: #ef5753;
$darkestGreyColor: #3d4852;
$redColor: #e3342f;
$darkRedColor: #621b18;
$orangeColor: #de751f;
$lightOrangeColor: #fcd9b6;
$adminLoginBGColor: #69aec2;
$lighterOrangeColor: #f6993f;
$lighterGreyColor: #f1f5f8;
$whiteGreyColor: #efefef;
$bronzeColor: #c7956d;
$checkBoxColor: #137cbd;
$lightestGreyColor: #f8fafc;
$borderRedColor: #ca4a35;
$redHoverColor: #a83e2c;
$indigoColor: #142581;
$indigoLightColor: #7983b8;
$lightBlueGreyColor: #dde0ed;
$previewBackgroundColor: #f9f9f9;
$purpleColor: #7d3c98;
$purpleColorDark: #7030A0;


$mainFont: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
  'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji',
  'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji' !important;

@mixin mainTile {
  flex: 0 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 2rem 3rem;
  max-width: 1280px;
  margin: 0 auto;
  height: calc(100% - 6rem);

  @media (min-width: 2500px) {
    max-width: 1480px;
  }
}

@mixin actionBtn {
  height: 2.275rem;
  margin-right: 0.5rem;
  text-transform: uppercase;
  letter-spacing: 0.025em;
  text-decoration: none;
  border-radius: 0.25rem;
  padding: 1rem 0.8rem;
  font-size: 0.75rem;
  font-weight: 700;
  border-bottom-width: 1px;
  background-color: $lightBlueColor;
  color: $whiteColor;
  border-color: $blueColor;

  &:disabled {
    background-color: $lightGreyColor;
    color: $backgroundColor;
    border-color: $lightGreyColor;
    cursor: not-allowed;
  }

  &:hover {
    background-color: $blueColor;
  }

  @media (max-width: 640px) {
    line-height: 1rem;
  }
}

@mixin base-button {
  font-size: 0.75rem;
  font-weight: 700;
  padding: 0.5rem 1rem;
  color: $whiteColor;
  text-transform: uppercase;
  margin-left: 1rem;
  text-align: start;
}

@mixin truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@mixin applyButton {
  margin-top: 2rem;
  width: 15rem;
  color: $whiteColor;
  outline: none;
}
